.container {
  width: 80%;
  margin: 50px auto;
}

.slider-container {
  margin-bottom: 20px;
}

.slider-container label {
  align-items: center;
  margin-bottom: 5px;
  display: flex;
}

.slider-container label label {
  align-items: center;
  margin-bottom: 0;
  display: flex;
}

.value-display {
  margin-right: 10px;
  font-weight: bold;
}

.slider {
  margin-top: 10px;
}

.disabled-slider .noUi-handle {
  cursor: not-allowed;
  background-color: #ccc;
}

.disabled-slider .noUi-connect {
  background: #eee;
}

.disabled-slider .noUi-base {
  background: #f5f5f5;
}

.disabled-slider {
  opacity: .1;
}

.noUi-target {
  width: 100%;
}

.label-content {
  margin-right: 5px;
}

.tooltip-icon {
  cursor: pointer;
  align-items: center;
  margin-left: 5px;
  display: inline-flex;
}

.tooltip-icon svg {
  width: 16px;
  height: 16px;
}

.tooltip-icon:hover svg circle, .tooltip-icon:focus svg circle {
  fill: #555;
}

.tippy-box[data-placement^="top"] > .tippy-arrow {
  border-top-color: #333;
}

.noUi-base {
  background: none;
  position: relative;
}

.noUi-connect {
  background: none;
}

.noUi-base {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.noUi-horizontal .noUi-handle {
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 5px #00000080;
}

#method-selector {
  margin-bottom: 20px;
}

#method-selector label {
  margin-right: 10px;
}

#method-selector select {
  padding: 5px;
}

div #inputs {
  margin-top: 30px;
}
/*# sourceMappingURL=index.2d3eab51.css.map */
