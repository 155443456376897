.container {
    width: 80%;
    margin: 50px auto;
  }
  
  .slider-container {
    margin-bottom: 20px;
  }
  
  .slider-container label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .slider-container label label {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
  }
  
  .value-display {
    font-weight: bold;
    margin-right: 10px;
  }
  
  .slider {
    margin-top: 10px;
  }

  /* Style for disabled sliders */
.disabled-slider .noUi-handle {
  background-color: #ccc;
  cursor: not-allowed;
}

.disabled-slider .noUi-connect {
  background: #eee;
}

.disabled-slider .noUi-base {
  background: #f5f5f5;
}

/* Optionally, adjust the opacity */
.disabled-slider {
  opacity: 0.1;
}
  
  .noUi-target {
    width: 100%;
  }

/* src/demo/styles.css */

/* ... existing styles ... */

/* Style for the label content */
.label-content {
  margin-right: 5px;
}

/* Style for the tooltip icon */
.tooltip-icon {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-left: 5px;
}

.tooltip-icon svg {
  width: 16px;
  height: 16px;
}

.tooltip-icon:hover svg circle {
  fill: #555; /* Darken circle on hover */
}

.tooltip-icon:focus svg circle {
  fill: #555; /* Darken circle on focus */
}

/* Optional: Adjust the position of the tooltip */
.tippy-box[data-placement^='top'] > .tippy-arrow {
  border-top-color: #333;
}

  
/* Ensure the .noUi-base element displays the gradient properly */
.noUi-base {
    position: relative;
    background: none; /* Remove any default background */
  }
  
  /* Adjust the .noUi-connect to be transparent */
  .noUi-connect {
    background: transparent;
  }
  
  /* Optional: Add a border or shadow to the slider track for better visibility */
  .noUi-base {
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Keep the handle visible and styled appropriately */
  .noUi-horizontal .noUi-handle {
    outline: none;
    border: none;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
    cursor: pointer;
  }
  
  /* Method selector dropdown */
  #method-selector {
    margin-bottom: 20px;
  }
  
  #method-selector label {
    margin-right: 10px;
  }
  
  #method-selector select {
    padding: 5px;
  }

  div #inputs {
    margin-top: 30px;
  }